import React from 'react';
import cln from 'classnames';

import styles from './QuestionIcons.module.css';

const QuestionIcons = () => (
  <div className={styles.wrapperQuestionIcons}>
    <img src="/icons/welcome/lines.svg" className={styles.bg} />

    <div className={cln(styles.question, styles.small)}>
      <img src="/icons/welcome/white-q.svg" className={styles.white} />
      <img src="/icons/welcome/colorful-q.svg" className={styles.colorful} />
    </div>
    <div className={styles.question}>
      <img src="/icons/welcome/white-q.svg" className={styles.white} />
      <img src="/icons/welcome/colorful-q.svg" className={styles.colorful} />
    </div>
    <div className={cln(styles.question, styles.small)}>
      <img src="/icons/welcome/white-q.svg" className={styles.white} />
      <img src="/icons/welcome/colorful-q.svg" className={styles.colorful} />
    </div>
  </div>
);

export default QuestionIcons;
