import { Socket, io } from 'socket.io-client';
import { useZustandAppStore } from 'stores/app/store';
import MainRoute from './routes';
import { getAppStateSelector, setAppStateAction } from 'stores/app/selector';
import { EAppState } from 'stores/app/types';
import Loader from 'components/ui/Loader';

import styles from './App.module.css';
import { useEffect, useRef, useState } from 'react';
import {
  getGameStateSelector,
  setGameStateAction,
} from 'stores/game/selectors';
import { useZustandGameStateStore } from 'stores/game/store';
import { EGameStatus } from 'stores/game/types';

function App() {
  const appState = useZustandAppStore(getAppStateSelector);

  const setAppState = useZustandAppStore(setAppStateAction);

  const gameStatus = useZustandGameStateStore(getGameStateSelector);

  const setGameState = useZustandGameStateStore(setGameStateAction);

  const [clientInfo, setClientInfo] = useState<null | { accessToken: string }>(
    null
  );

  const socket = useRef<null | Socket>(null);

  const callback = (event: any) => {
    try {
      const { data } = event;
      const { type, payload } = data;

      switch (type) {
        case 'clientInfo':
          setClientInfo(payload);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('🚀 ~ callback ~ error:', error);
    }
  };

  const sendCommand = (data: any) => {
    // socket.current?.emit('client-tokyo-slot-spin', data)
  };

  useEffect(() => {
    window.addEventListener('message', callback, true);

    window?.parent?.postMessage({ type: 'in-house-game' }, '*');

    return () => {
      window.removeEventListener('message', callback, true);
    };
  }, []);

  useEffect(() => {
    if (!clientInfo) {
      return () => {
        socket.current?.disconnect();
        socket.current = null;
      };
    }

    console.info(process.env.REACT_APP_URL, 'SOCKET URL');
    console.info(clientInfo, 'clientInfo');

    socket.current = io(process.env.REACT_APP_URL as string);

    socket.current.on('connect', () => {
      socket.current?.emit('authorize-client', { clientInfo });
    });

    socket.current.on('verified', () => {
      console.info('verified');
      setAppState(EAppState.Available);
    });

    socket.current.on('bet-result', (result: any) => {
      console.log('🚀 ~ socket.current.on ~ result:', result);
      // setGameState(EGameStatus.Result);
      // setSlotResult(result);
    });

    return () => {
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [clientInfo, setAppState, setGameState]);

  useEffect(() => {
    if (!socket.current || gameStatus !== EGameStatus.Run) {
      return;
    }

    sendCommand({});
  }, [gameStatus]);

  return (
    <>
      <MainRoute />

      {appState === EAppState.Loading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </>
  );
}

export default App;
