import React from 'react';

import styles from './Points.module.css';

const Points = () => (
  <div className={styles.points}>
    <div className={styles.title}>Points</div>
    <div className={styles.amount}>231</div>
  </div>
);

export default Points;
