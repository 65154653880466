import React from 'react';
import cln from 'classnames';
import { ButtonBaseProps } from './types';

import styles from './ButtonBase.module.css';

const ButtonBase = (props: ButtonBaseProps) => {
  const {
    text,
    startIcon,
    children,
    size,
    isResized,
    classes = '',
    marginL = '',
    fontSize = '',
    borderR = '',
    classesText,
    centreText,
    handleClick,
    buttonHeight,
    marginB,
    disabled,
  } = props;

  const marginLKey = `marginLeft-${marginL}`;

  const marginBKey = `marginBottom-${marginB}`;

  const sizeKey = `size${size}`;

  const fontSizeKey = `fontSize${fontSize}`;

  const borderRKey = `borderRadius${borderR}`;

  const buttonHeightKey = `buttonHeight${buttonHeight}`;

  return (
    <button
      onClick={handleClick}
      className={cln(
        styles.wrapperButtonBase,
        styles[sizeKey],
        styles[marginLKey],
        styles[marginBKey],
        styles[fontSizeKey],
        styles[borderRKey],
        {
          [styles.resizedButton]: isResized,
          [styles[buttonHeightKey]]: buttonHeight,
          [styles.disabled]: disabled,
        },
        classes
      )}
    >
      {startIcon}

      {text && (
        <div
          className={cln(styles.textContainer, classesText, {
            [styles.center]: centreText,
          })}
        >
          {text}
        </div>
      )}

      {children}
    </button>
  );
};

export default ButtonBase;
