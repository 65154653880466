import cln from 'classnames';
import ButtonBase from 'components/ui/Buttons/ButtonBase';
import { ReactComponent as CloseSVG } from 'assets/base/close.svg';
import { ReactComponent as CheckSVG } from 'assets/results/check.svg';
import { ReactComponent as StarSVG } from 'assets/results/star.svg';
import { ReactComponent as BigStarSVG } from 'assets/results/bigStar.svg';

import { STARS, RECIEVED_STARS } from './constants';

import styles from './Results.module.css';
import { useNavigate } from 'react-router-dom';
import { ERouteNames } from 'routes/types';

const stars = Array(STARS).fill(null);

const Results = () => {
  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(ERouteNames.Multiplayer);
  };

  return (
    <div className={styles.wrapperResults}>
      <div className={styles.close} onClick={jumpTo}>
        <CloseSVG />
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>Nice Work</div>

          <CheckSVG />

          <div className={styles.stars}>
            {stars.map((_, idx) =>
              idx % 2 ? (
                <BigStarSVG
                  key={idx}
                  className={cln(styles.star, {
                    [styles.filled]: idx + 1 <= RECIEVED_STARS,
                  })}
                />
              ) : (
                <StarSVG
                  key={idx}
                  className={cln(styles.star, {
                    [styles.filled]: idx + 1 <= RECIEVED_STARS,
                  })}
                />
              )
            )}
          </div>

          <div className={styles.pointsAmount}>
            You Earned <b>80 pts</b>
          </div>
        </div>

        <div className={styles.buttons}>
          <ButtonBase
            text="Next Stage"
            size="--button-full"
            buttonHeight="--button-height-full"
            classes={styles.next}
            centreText
          />
          <ButtonBase
            text="Play Again"
            size="--button-full"
            buttonHeight="--button-height-full"
            classes={styles.playAgain}
            centreText
          />
        </div>
      </div>
    </div>
  );
};

export default Results;
