import { create } from 'zustand';

import { EAppState, ZustandAppStore } from './types';

export const useZustandAppStore = create<ZustandAppStore>((set) => ({
  appState: EAppState.Loading,

  setAppState: (appState) => {
    set({ appState });
  },
}));
