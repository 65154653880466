import { useState } from 'react';
import cln from 'classnames';

import Header from 'components/ui/Header';
import pencilPath from 'assets/questions/Pencil.png';

import { OPTIONS } from './constants';

import styles from './Question.module.css';
import { useNavigate } from 'react-router-dom';
import { ERouteNames } from 'routes/types';

const Question = () => {
  const [option, setOption] = useState<number | null>(null);

  const navigate = useNavigate();

  const handleChooseOption = (idx: number) => () => {
    setOption(idx);
    navigate(ERouteNames.Results);
  };

  return (
    <div className={styles.wrapperQuestion}>
      <div className={styles.header}>
        <Header title="Question 1" />

        <div className={styles.progressLine}>
          <div className={styles.insideLine}></div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgContainer}>
          <img src={pencilPath} alt="" />
        </div>

        <div className={styles.text}>
          what is <br /> this thing?
        </div>
      </div>

      <div className={styles.options}>
        {OPTIONS.map((o, idx) => (
          <div
            key={idx}
            onClick={handleChooseOption(idx)}
            className={cln(styles.option, {
              [styles.chosen]: option === idx,
            })}
          >
            {o}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
