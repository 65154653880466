import { ReactComponent as ArrowSVG } from 'assets/base/arrow.svg';
import { ReactComponent as SwordsSVG } from 'assets/multiplayer/swords.svg';
import { ReactComponent as ContactsSVG } from 'assets/multiplayer/contacts.svg';
import { ReactComponent as UsersSVG } from 'assets/multiplayer/user.svg';

import styles from './MultiPlayer.module.css';
import Avatar from 'components/Avatar';
import Progress from 'components/Progress';
import Points from 'components/Points';
import { ERouteNames } from 'routes/types';
import { useNavigate } from 'react-router-dom';

const MultiPlayer = () => {
  const navigate = useNavigate();

  const jumpTo = (path: ERouteNames) => () => {
    navigate(path);
  };

  return (
    <div className={styles.wrapperMultiPlayer}>
      <img src="/icons/welcome/lines.svg" className={styles.bg} />

      <div className={styles.userProgress}>
        {/* <Avatar /> */}

        <Points />

        {/* <Progress /> */}
      </div>

      <div className={styles.navigateBtns}>
        <div className={styles.left}>
          <ArrowSVG />
        </div>

        <SwordsSVG className={styles.swords} />

        <div className={styles.right}>
          <ArrowSVG />
        </div>
      </div>

      <div className={styles.text}>
        <div>Choose a</div>
        <div>Type Of GAME</div>
      </div>

      <div className={styles.footer}>
        <ContactsSVG className={styles.btn} onClick={jumpTo(ERouteNames.Map)} />

        <UsersSVG
          className={styles.btn}
          onClick={jumpTo(ERouteNames.Question)}
        />
      </div>
    </div>
  );
};

export default MultiPlayer;
