import { Route, Routes } from 'react-router-dom';
import { ERouteNames } from './types';

import MultiPlayer from 'pages/MultiPlayer';
import Map from 'pages/Map';
import Question from 'pages/Question';
import Results from 'pages/Results';
import Welcome from 'pages/Welcome';

const MainRoute = () => (
  <Routes>
    <Route path={ERouteNames.Welcome} element={<Welcome />} />

    <Route path={ERouteNames.Multiplayer} element={<MultiPlayer />} />

    <Route path={ERouteNames.Map} element={<Map />} />

    <Route path={ERouteNames.Question} element={<Question />} />

    <Route path={ERouteNames.Results} element={<Results />} />
  </Routes>
);

export default MainRoute;
