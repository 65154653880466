import React from 'react';
import cln from 'classnames';
import { ReactComponent as LockSVG } from 'assets/map/lock.svg';
import { ReactComponent as GiftSVG } from 'assets/map/gift.svg';
import { RewardType } from 'stores/map/types';
import styles from './Reward.module.css';
import { useNavigate } from 'react-router-dom';
import { ERouteNames } from 'routes/types';

type Props = {
  classes?: string;
  title?: string | number;
  iconType?: RewardType;
  disabled?: boolean;
};

const Reward: React.FC<Props> = (props) => {
  const { title, classes, iconType, disabled } = props;

  const navigate = useNavigate();

  const jumpTo = () => {
    if (iconType === RewardType.Gift) {
      alert('GIFT');

      return;
    }
    navigate(ERouteNames.Question);
  };

  return (
    <div
      className={cln(styles.wrapperReward, classes, {
        [styles.disabled]: disabled || iconType === RewardType.Lock,
      })}
      onClick={jumpTo}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="80"
        height="80"
        className={cln(styles.hexagon, {
          [styles.level]: iconType === RewardType.Level,
        })}
      >
        <defs>
          <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="4"
              floodColor="var(--shadow-color-purple)"
            />
          </filter>
        </defs>
        <path
          d="M50 0 L90 25 L90 75 L50 100 L10 75 L10 25 Z"
          fill="var(--primary-color-aqua)"
          rx="24"
          stroke="var(--primary-color-white)"
          strokeWidth={2}
          strokeLinejoin="round"
          strokeLinecap="round"
          transform="translate(1 1)"
          filter="url(#drop-shadow)"
        />
        <foreignObject x="10" y="25" width="80" height="50">
          <div className={styles.content}>
            {iconType === RewardType.Lock && <LockSVG />}

            {iconType === RewardType.Gift && <GiftSVG />}

            {title && <span>{title}</span>}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default Reward;
