import ButtonBase from 'components/ui/Buttons/ButtonBase';

import { ReactComponent as ArrowBack } from 'assets/base/arrow.svg';
import { ReactComponent as More } from 'assets/map/more.svg';

import styles from './Header.module.css';
import { useNavigate } from 'react-router';

type Props = {
  title: string;
};

const Header = ({ title }: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.header}>
      <ButtonBase
        startIcon={<ArrowBack />}
        size="--button-xs"
        classes={styles.button}
        handleClick={goBack}
      />

      <div className={styles.title}>{title}</div>

      <ButtonBase
        startIcon={<More />}
        size="--button-xs"
        classes={styles.button}
      />
    </div>
  );
};

export default Header;
