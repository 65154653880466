import cln from 'classnames';
import styles from './Loader.module.css';

type Props = {
  classes?: string;
};

const Loader = ({ classes }: Props) => (
  <div className={cln(styles.wrapperLoader, classes)}>
    <div className={styles.insideCircular} />
  </div>
);

export default Loader;
