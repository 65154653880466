import { useNavigate } from 'react-router-dom';

import Header from 'components/ui/Header';
import Reward from './components/Reward';

import linePath from 'assets/map/line.png';
import { RewardType } from 'stores/map/types';

import { ERouteNames } from 'routes/types';

import styles from './Map.module.css';

const Map = () => {
  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(ERouteNames.Question);
  };

  return (
    <div className={styles.wrapperMap}>
      <Header title="Map 1" />

      <div className={styles.levels}>
        <img src={linePath} alt="" className={styles.line} />

        <div className={styles.left}>
          <Reward iconType={RewardType.Gift} />

          <img src={linePath} alt="" className={styles.line} />

          <Reward iconType={RewardType.Lock} />
        </div>

        <Reward iconType={RewardType.Lock} />

        <div className={styles.right}>
          <Reward iconType={RewardType.Lock} />

          <img src={linePath} alt="" className={styles.line} />

          <Reward iconType={RewardType.Gift} />
        </div>

        <Reward iconType={RewardType.Level} title="x2" />
      </div>
    </div>
  );
};

export default Map;
