import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cln from 'classnames';

import ButtonBase from '../../components/ui/Buttons/ButtonBase';
import QuestionIcons from './components/QuestionIcons';
import { ReactComponent as ArrowSVG } from '../../assets/welcome/arrowRight.svg';
import { WELCOME_TEXT } from './constants';

import styles from './Welcome.module.css';
import { ERouteNames } from 'routes/types';

const Welcome = () => {
  const navigate = useNavigate();

  const [activePage, setActivePage] = useState(0);

  const handleChangeStep = (idx: number) => () => {
    setActivePage(idx);
  };

  const jumpTo = () => {
    navigate(ERouteNames.Multiplayer);
  };

  return (
    <div className={styles.wrapperWelcome}>
      <QuestionIcons />

      <div className={styles.welcomeContent}>
        <div className={styles.card}>
          {WELCOME_TEXT.map(
            (card, idx) =>
              idx === activePage && (
                <div key={idx}>
                  <div className={styles.title}>{card.title}</div>
                  <div className={styles.subtitle}>{card.subtitle}</div>
                </div>
              )
          )}

          <div className={styles.steps}>
            {WELCOME_TEXT.map((card, idx) => (
              <div
                key={idx}
                onClick={handleChangeStep(idx)}
                className={cln(styles.step, {
                  [styles.active]: activePage === idx,
                })}
              ></div>
            ))}
          </div>
        </div>

        <ButtonBase
          startIcon={<ArrowSVG />}
          size="--button-l"
          buttonHeight="--button-height-max"
          classes={styles.nextBtn}
          handleClick={jumpTo}
        />
      </div>
    </div>
  );
};

export default Welcome;
