export interface ZustandAppStore extends useZustandAppEntities {
  setAppState: (s: EAppState) => void;
}

interface useZustandAppEntities {
  appState: EAppState;
}

export const enum EAppState {
  Loading,
  Available,
}
